<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("cancelTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row no-gutters class="mx-4 mt-4 mb-2" justify="center">
          <v-select
            v-model="reason"
            :items="reasonList"
            :menu-props="{ bottom: true, offsetY: true }"
            outlined
            dense
            required
            :error-messages="reasonErrors"
            @input="$v.reason.$touch()"
            @blur="$v.reason.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("reason") }}
            </template>
            <template slot="item" slot-scope="data">
              {{ getReasonText(data.item.text) }}
            </template>
            <template v-slot:selection="{ item }">
              {{ getReasonText(item.text) }}
            </template>
          </v-select>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-2" justify="center">
          <v-textarea
            v-model="remarks"
            outlined
            label="Remarks"
            dense
            required
            rows="2"
            :error-messages="remarksErrors"
            @input="$v.remarks.$touch()"
            @blur="$v.remarks.$touch()"
          ></v-textarea>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-2" justify="center">
          <v-textarea
            v-model="comments"
            outlined
            dense
            required
            rows="2"
            label="Comments"
            :error-messages="commentsErrors"
            @input="$v.comments.$touch()"
            @blur="$v.comments.$touch()"
          ></v-textarea>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="cancelTicketSubmit"
            :disabled="$v.$invalid"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid"
              @click="cancelTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import cancelTicketReason from "@/static/cancelTicketReason.json";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations() {
    return {
      reason: { required },
      remarks: { maxLength: maxLength(1024) },
      comments: { maxLength: maxLength(1024) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      reason: "",
      reasonList: cancelTicketReason.ncoccCancelTicketReason,
      comments: "",
      remarks: "",
    };
  },
  methods: {
    async cancelTicketSubmit() {
      let cancelTicketPayload = {
        body: {
          reason: this.reason,
        },
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          reason: this.reason,
          remarks: this.remarks,
          comments: this.comments,
        },
      };
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      cancelTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionCancelTicket",
        cancelTicketPayload
      );
      if (updateTicketResp.status === "error") {
        console.log("Error in Updating Cancel Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "cancelTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.closeDialog();
    },
    closeDialog() {
      this.reason = "";
      this.comments = "";
      this.remarks = "";
      this.$emit("closeDialog");
    },
    getReasonText(data) {
      return data.charAt(0) + data.slice(1).toLowerCase();
    },
  },
  computed: {
    reasonErrors() {
      const errors = [];
      if (!this.$v.reason.$dirty) return errors;
      !this.$v.reason.required && errors.push(this.$t("reasonRequired"));
      return errors;
    },
    remarksErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.maxLength &&
        errors.push(this.$t("Remarks must be at most 1024 characters long"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.comments.$dirty) return errors;
      !this.$v.comments.maxLength &&
        errors.push(this.$t("Comments must be at most 1024 characters long"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped></style>
