<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateNC"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!destroyedMarksNC"
        @click="updateTicketDetails('DestroyedMarks')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("destroyedMarks") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="hourNoticeTicket"
        :disabled="!threeHourNoticeNC"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("3-HrNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="retransmitTicket"
        :disabled="!retrasmitNC"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelNC"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateNC"
        @click="updateTicketDetails('UpdateTicket')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="140"
        :disabled="!destroyedMarksNC"
        @click="updateTicketDetails('DestroyedMarks')"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("destroyedMarks") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!threeHourNoticeNC"
        @click="hourNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
      >
        {{ $t("3-HrNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="retransmitTicket"
        :disabled="!retrasmitNC"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelNC"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>

    <CancelTicketDialog
      v-if="cancelTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RetransmitTicketDialog
      v-if="retransmitTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="retransmitTicketDialog"
      @retransmitTicketSubmit="retransmitTicketSubmit"
      @closeRetransmitDialog="closeRetransmitTicketDialog"
      @retransmitTicketSuccess="retransmitTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <HourNotice
      v-if="hourNoticeTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="hourNoticeTicketDialog"
      @hourNoticeTicketSubmit="hourNoticeTicketSubmit"
      @closeHourNoticeTicketDialog="closeHourNoticeTicketDialog"
      @hourNoticeTicketSuccess="hourNoticeTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import CancelTicketDialog from "./CancelTicketDialog.vue";
import EditTicket from "@/mixins/EditTicket";
import RetransmitTicketDialog from "./RetransmitTicketDialog.vue";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
import HourNotice from "./3HourNotice.vue";
import ErrorMessage from "../../../../molecules/ErrorMessage.vue";

export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    CancelTicketDialog: () => import("./CancelTicketDialog.vue"),
    RetransmitTicketDialog: () => import("./RetransmitTicketDialog.vue"),
    HourNotice: () => import("./3HourNotice.vue"),
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelNC: false,
    destroyedMarksNC: false,
    retrasmitNC: false,
    threeHourNoticeNC: false,
    updateNC: false,
    currentTab: "Tasks",
    dialog: true,
    cancelTicketDialog: false,
    retransmitTicketDialog: false,
    hourNoticeTicketDialog: false,
    latestHourNoticeRevision: "",
    isViewProjectDetails: false,
    addProjectDialog: false,
    isUploadFiles: false,
    ticketData: {},
    viewExpantionPanel: false,
    errorCode: "",
    errorMessage: "",
    isError: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
      console.log(data);
    },
    retransmitTicket() {
      this.retransmitTicketDialog = true;
    },
    hourNoticeTicket() {
      this.hourNoticeTicketDialog = true;
    },
    closeHourNoticeTicketDialog() {
      this.hourNoticeTicketDialog = false;
    },
    hourNoticeTicketSubmit() {
      this.hourNoticeTicketDialog = false;
    },
    async hourNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.hourNoticeTicketDialog = false;
      this.$emit("hourNoticeTicketSuccess", ticketNumber, ticketRevision);
    },
    closeRetransmitTicketDialog() {
      this.retransmitTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.retransmitTicketDialog = false;
    },
    async retransmitTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.retransmitTicketDialog = false;
      this.$emit(
        "retransmitTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },
    async updateTicketDetails(editType) {
      await this.updateTicket(this.ticketDetails, editType);
      this.$router.push("/createTicket").catch();
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },

    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
