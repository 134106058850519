<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("3-HrNoticeTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-5"
          :class="contactErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="contact"
            outlined
            dense
            required
            :hide-details="contactErrors.length === 0"
            :error-messages="contactErrors"
            @input="$v.contact.$touch()"
            @blur="$v.contact.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("contactName") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="contactPhoneErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="contact_phone"
            :label="$t('contactPhone')"
            outlined
            dense
            required
            :hide-details="contactPhoneErrors.length === 0"
            :error-messages="contactPhoneErrors"
            @input="$v.contact_phone.$touch()"
            @blur="$v.contact_phone.$touch()"
          ></v-text-field>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="subcontractorErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="subcontractor"
            outlined
            dense
            required
            :hide-details="subcontractorErrors.length === 0"
            :error-messages="subcontractorErrors"
            @input="$v.subcontractor.$touch()"
            @blur="$v.subcontractor.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("subcontractorName") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="subcontractorPhoneErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="subcontractor_phone"
            :label="$t('subcontractorPhone')"
            outlined
            dense
            required
            :hide-details="subcontractorPhoneErrors.length === 0"
            :error-messages="subcontractorPhoneErrors"
            @input="$v.subcontractor_phone.$touch()"
            @blur="$v.subcontractor_phone.$touch()"
          ></v-text-field>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="remarksErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-textarea
            v-model="remarks"
            outlined
            label="Remarks"
            dense
            required
            rows="2"
            :hide-details="remarksErrors.length === 0"
            :error-messages="remarksErrors"
            @input="$v.remarks.$touch()"
            @blur="$v.remarks.$touch()"
          ></v-textarea>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-2" justify="center">
          <v-textarea
            v-model="comments"
            outlined
            dense
            required
            rows="2"
            :label="$t('comments')"
            :error-messages="commentsErrors"
            @input="$v.comments.$touch()"
            @blur="$v.comments.$touch()"
          ></v-textarea>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="hourNoticeTicketSubmit"
            :disabled="$v.$invalid"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid"
              @click="hourNoticeTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
export default {
  mixins: [validationMixin],
  validations() {
    return {
      contact: { required, maxLength: maxLength(30), minLength: minLength(1) },
      contact_phone: { phone },
      subcontractor_phone: { phone },
      subcontractor: {
        required,
        maxLength: maxLength(30),
        minLength: minLength(1),
      },
      remarks: { maxLength: maxLength(8192) },
      comments: { maxLength: maxLength(8192) },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      contact: "",
      contact_phone: "",
      subcontractor: "",
      subcontractor_phone: "",
      comments: "",
      remarks: "",
    };
  },
  methods: {
    async hourNoticeTicketSubmit() {
      let hourNoticeTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          caller: this.ticketData.caller,
          contact: this.contact,
          subcontractor: this.subcontractor,
          remarks: this.remarks,
          comments: this.comments,
        },
      };
      if (this.contact_phone && this.contact_phone.length > 0) {
        apiData.data.contact_phone = this.contact_phone;
      }
      if (this.subcontractor_phone && this.subcontractor_phone.length > 0) {
        apiData.data.subcontractor_phone = this.subcontractor_phone;
      }
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      hourNoticeTicketPayload.apiData = apiData;
      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionHourNoticeTicket",
        hourNoticeTicketPayload
      );

      if (updateTicketResp.status === "error") {
        console.log("Error in Updating 3 Hour Notice Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "hourNoticeTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.closeDialog();
    },
    closeDialog() {
      this.contact = "";
      this.contact_phone = "";
      this.subcontractor = "";
      this.subcontractor_phone = "";
      this.remarks = "";
      this.comments = "";
      this.$emit("closeHourNoticeTicketDialog");
    },
  },
  computed: {
    subcontractorErrors() {
      const errors = [];
      if (!this.$v.subcontractor.$dirty) return errors;
      !this.$v.subcontractor.maxLength &&
        errors.push(this.$t("subcontractorMaxLength"));
      !this.$v.contact.required &&
        errors.push(this.$t("Subcontractor is required"));
      !this.$v.contact.minLength &&
        errors.push(this.$t("subcontractorMaxLimit"));
      return errors;
    },
    subcontractorPhoneErrors() {
      const errors = [];
      if (!this.$v.subcontractor_phone.$dirty) return errors;
      !this.$v.subcontractor_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required && errors.push(this.$t("contactRequired"));
      !this.$v.contact.minLength && errors.push(this.$t("contactMinLength"));
      !this.$v.contact.maxLength && errors.push(this.$t("contactMaxLength"));
      return errors;
    },
    contactPhoneErrors() {
      const errors = [];
      if (!this.$v.contact_phone.$dirty) return errors;
      !this.$v.contact_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
    remarksErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.maxLength && errors.push(this.$t("remarksMaxLimit"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.comments.$dirty) return errors;
      !this.$v.comments.maxLength && errors.push(this.$t("commentsMaxLimit"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
</style>
