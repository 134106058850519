<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="480"
      max-height="400"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("retransmitTicket") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-4"
          :class="doneForErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="done_for"
            outlined
            dense
            required
            :hide-details="doneForErrors.length === 0"
            :error-messages="doneForErrors"
            @input="$v.done_for.$touch()"
            @blur="$v.done_for.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("doneFor") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="contactErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="contact"
            outlined
            dense
            required
            :hide-details="contactErrors.length === 0"
            :error-messages="contactErrors"
            @input="$v.contact.$touch()"
            @blur="$v.contact.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("contact") }}
            </template></v-text-field
          >
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="contactPhoneErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="contact_phone"
            :label="$t('contactPhone')"
            outlined
            dense
            required
            :hide-details="contactPhoneErrors.length === 0"
            :error-messages="contactPhoneErrors"
            @input="$v.contact_phone.$touch()"
            @blur="$v.contact_phone.$touch()"
          ></v-text-field>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="subcontractorErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="subcontractor"
            :label="$t('subcontractor')"
            outlined
            dense
            required
            :hide-details="subcontractorErrors.length === 0"
            :error-messages="subcontractorErrors"
            @input="$v.subcontractor.$touch()"
            @blur="$v.subcontractor.$touch()"
          ></v-text-field>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="subcontractorPhoneErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-text-field
            v-model="subcontractor_phone"
            :label="$t('subcontractorPhone')"
            outlined
            dense
            required
            :hide-details="subcontractorPhoneErrors.length === 0"
            :error-messages="subcontractorPhoneErrors"
            @input="$v.subcontractor_phone.$touch()"
            @blur="$v.subcontractor_phone.$touch()"
          ></v-text-field>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="retransmitRemarksErrors.length === 0 ? 'mb-6' : 'mb-0'"
          justify="center"
        >
          <v-textarea
            v-model="retransmitRemarks"
            outlined
            dense
            required
            rows="2"
            :hide-details="retransmitRemarksErrors.length === 0"
            :error-messages="retransmitRemarksErrors"
            @input="$v.retransmitRemarks.$touch()"
            @blur="$v.retransmitRemarks.$touch()"
            ><template v-slot:label>
              <span class="red--text"> *</span> {{ $t("retransmitRemarks") }}
            </template></v-textarea
          >
        </v-row>
        <v-row no-gutters class="mx-4 mt-2 mb-2" justify="center">
          <v-textarea
            v-model="comments"
            outlined
            dense
            required
            rows="2"
            :label="$t('comments')"
            :error-messages="commentsErrors"
            @input="$v.comments.$touch()"
            @blur="$v.comments.$touch()"
          ></v-textarea>
        </v-row>
        <v-row no-gutters class="mx-4 mt-2" justify="center">
          <v-autocomplete
            v-model="selectedMembersToNotify"
            :items="membersToNotifyList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('membersToNotify')"
            outlined
            dense
            required
            multiple
            item-text="mbcode_name"
            item-value="mbcode"
            @input="searchMembers = null"
            :search-input.sync="searchMembers"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-text-field
                  v-model="searchMembers"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchMembersToNotify')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2 chip-content">
                  {{ item.mbcode_name }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row
          no-gutters
          class="mx-4 mt-2"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-16' : ''"
          justify="center"
        >
          <v-autocomplete
            v-model="selectedMembersToAdd"
            :items="membersToAddList"
            :menu-props="{ bottom: true, offsetY: true }"
            :label="$t('membersToAdd')"
            outlined
            dense
            required
            multiple
            item-text="mbcode_name"
            item-value="mbcode"
            @change="updateMemberCode"
            :error-messages="membersToAddError"
            @input="$v.membersToAddListString.$touch()"
            @blur="$v.membersToAddListString.$touch()"
            :search-input.sync="searchCountyMembers"
          >
            >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-text-field
                  v-model="searchCountyMembers"
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('searchMembersToAdd')"
                  single-line
                  hide-details
                  flat
                  dense
                  variant="underlined"
                  clear-icon="mdi-close-circle"
                  clearable
                >
                </v-text-field>
              </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                size="small"
                density="compact"
                v-bind="attrs"
                :input-value="selected"
              >
                <span class="pr-2 chip-content">
                  {{ item.mbcode_name }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-row>
        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="retransmitTicketSubmit"
            :disabled="$v.$invalid || submitButtonClicked"
          >
            {{ $t("submit") }}
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="space-between" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0"
              width="50%"
              depressed
              :disabled="$v.$invalid || submitButtonClicked"
              @click="retransmitTicketSubmit"
              ><span class="white--text text-none">{{ $t("submit") }}</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
export default {
  mixins: [validationMixin],
  validations() {
    return {
      done_for: { required, maxLength: maxLength(40), minLength: minLength(1) },
      contact: { required, maxLength: maxLength(30), minLength: minLength(1) },
      contact_phone: { phone },
      subcontractor_phone: { phone },
      subcontractor: { maxLength: maxLength(30) },
      retransmitRemarks: { required, minLength: minLength(1) },
      comments: { maxLength: maxLength(1024) },
      membersToAddListString: {
        maxLength: maxLength(150),
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      done_for: "",
      contact: "",
      contact_phone: "",
      subcontractor: "",
      subcontractor_phone: "",
      selectedMembersToNotify: [],
      selectedMembersToAdd: [],
      comments: "",
      retransmitRemarks: "",
      searchMembers: null,
      searchCountyMembers: null,
      membersToAddList: [],
      submitButtonClicked: false,
      membersToAddListString: null,
    };
  },
  async created() {
    if (this.ticketData && this.ticketData.center) {
      await this.getMembersToAdd();
    }
  },
  watch: {
    async ticketData() {
      await this.getMembersToAdd();
    },
  },
  methods: {
    updateMemberCode() {
      this.membersToAddListString = this.selectedMembersToAdd.join(",");
    },
    checkFacilityType(facility_type) {
      //check if facility type is a 4 character code ending in X
      if (
        !facility_type ||
        (facility_type.length == 4 && facility_type.endsWith("X"))
      ) {
        return false;
      }

      // check to see if facility type is not in the array of facility types that are not required to respond
      const notRequiredFacilityType = ["LOCT", "COPY", "TEST", "ENCR", "PIGB"];
      return !notRequiredFacilityType.includes(facility_type);
    },
    showMember(member) {
      if (
        member.show &&
        member.deliver &&
        this.checkFacilityType(member.facility_type)
      ) {
        return true;
      }
      return false;
    },
    async retransmitTicketSubmit() {
      this.submitButtonClicked = true;
      let retransmitTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };
      let apiData = {
        data: {
          caller: this.ticketData.caller,
          done_for: this.done_for,
          contact: this.contact,
          subcontractor: this.subcontractor,
          retransmitRemarks: this.retransmitRemarks,
          comments: this.comments,
        },
      };
      if (this.contact_phone && this.contact_phone.length > 0) {
        apiData.data.contact_phone = this.contact_phone;
      }
      if (this.subcontractor_phone && this.subcontractor_phone.length > 0) {
        apiData.data.subcontractor_phone = this.subcontractor_phone;
      }
      if (
        this.selectedMembersToNotify &&
        this.selectedMembersToNotify.length !== 0
      ) {
        apiData.data.membersToNotify = this.selectedMembersToNotify.join(",");
      } else apiData.data.membersToNotify = "";
      if (this.selectedMembersToAdd && this.selectedMembersToAdd.length !== 0) {
        apiData.data.membersToAdd = this.selectedMembersToAdd.join(",");
      } else apiData.data.membersToAdd = "";
      apiData.ticket = this.ticketData.ticket;
      apiData.center = this.ticketData.centerData.center;
      retransmitTicketPayload.apiData = apiData;

      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionRetransmitTicket",
        retransmitTicketPayload
      );

      if (updateTicketResp.status === "error") {
        console.log("Error in Updating Retransmitting Ticket Details");
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "retransmitTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.submitButtonClicked = false;
      this.closeDialog();
    },
    async getMembersToAdd() {
      this.membersToAddList = [];
      let membersToAddArray = [];
      if (this.ticketData && this.ticketData.centerData) {
        const membersToAddData = await this.$store.dispatch(
          "getMembersDetailsInCounty",
          {
            state: this.ticketData.state,
            county: this.ticketData.county,
            url: this.ticketData.centerData.url,
            center: this.ticketData.centerData.center,
          }
        );
        if (membersToAddData.status !== "error") {
          membersToAddArray = membersToAddData.map(({ code, name }) => {
            return {
              mbcode: code,
              name,
              mbcode_name: `${code} - ${name}`,
            };
          });
          this.membersToAddList = membersToAddArray;
        }
      }
    },
    closeDialog() {
      this.done_for = "";
      this.contact = "";
      this.contact_phone = "";
      this.subcontractor = "";
      this.subcontractor_phone = "";
      this.retransmitRemarks = "";
      this.searchMembers = null;
      this.searchCountyMembers = null;
      this.comments = "";
      this.selectedMembersToNotify = [];
      this.selectedMembersToAdd = [];
      this.$emit("closeRetransmitDialog");
    },
  },
  computed: {
    membersToNotifyList() {
      let membersList = [];
      if (
        this.ticketData &&
        this.ticketData.receipt &&
        this.ticketData.receipt.members
      ) {
        membersList = this.ticketData.receipt.members.map((member) => {
          if (this.showMember(member)) {
            return {
              mbcode: member.mbcode,
              name: member.name,
              mbcode_name: `${member.mbcode} - ${member.name}`,
            };
          }
        });
      }
      return membersList;
    },
    doneForErrors() {
      const errors = [];
      if (!this.$v.done_for.$dirty) return errors;
      !this.$v.done_for.required && errors.push(this.$t("doneForRequired"));
      !this.$v.done_for.minLength && errors.push(this.$t("doneForMinLength"));
      !this.$v.done_for.maxLength && errors.push(this.$t("doneForMaxLength"));
      return errors;
    },
    subcontractorErrors() {
      const errors = [];
      if (!this.$v.subcontractor.$dirty) return errors;
      !this.$v.subcontractor.maxLength &&
        errors.push(this.$t("subcontractorMaxLength"));
      return errors;
    },
    subcontractorPhoneErrors() {
      const errors = [];
      if (!this.$v.subcontractor_phone.$dirty) return errors;
      !this.$v.subcontractor_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required && errors.push(this.$t("contactRequired"));
      !this.$v.contact.minLength && errors.push(this.$t("contactMinLength"));
      !this.$v.contact.maxLength && errors.push(this.$t("contactMaxLength"));
      return errors;
    },
    contactPhoneErrors() {
      const errors = [];
      if (!this.$v.contact_phone.$dirty) return errors;
      !this.$v.contact_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      return errors;
    },
    retransmitRemarksErrors() {
      const errors = [];
      if (!this.$v.retransmitRemarks.$dirty) return errors;
      !this.$v.retransmitRemarks.required &&
        errors.push(this.$t("retransmitRequired"));
      !this.$v.retransmitRemarks.minLength &&
        errors.push(this.$t("retransmitMinLength"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.comments.$dirty) return errors;
      !this.$v.comments.maxLength && errors.push(this.$t("commentsMaxLength"));
      return errors;
    },
    membersToAddError() {
      const errors = [];
      if (!this.$v.membersToAddListString.$dirty) return errors;
      !this.$v.membersToAddListString.maxLength &&
        errors.push(this.$t("maxMembersAllowed"));
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}
.chip-content {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
